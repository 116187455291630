<template>
  <v-container class="container--fluid grid-list-md">
    <v-row>
      <v-col
        class="flex-grow-1"
        cols="12"
      >
        <v-alert
          :value="true"
          color="info"
          outlined
          dense
        >
          {{ $t('components.tinymceTips') }}
          <a
            target="_blank"
            href="https://www.tiny.cloud/docs/"
          >
            {{ $t('components.documentation') }}
          </a>
        </v-alert>
      </v-col>
      <v-col
        class="flex-grow-1"
        cols="12"
      >
        <!-- TODO: Criar conta https://www.tiny.cloud/ e inserir API-KEY. Essa api-key é válida ate 29/08/2020 -->
        <tinymce
          v-model="content"
          api-key="mw7w3roesv0yav0eujuo88u0u2aymq4dkb0fabuyuafygqkh"
          :init="options"
        />
      </v-col>
      <v-col
        class="flex-grow-1"
        cols="12"
      >
        <div class="text-h4">
          HTML content that will be saved in the Database.
        </div><hr>
        <div class="editor-content">
          {{ content }}
        </div><hr>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tinymce from '@tinymce/tinymce-vue';
// import EditorImage from '@/components/Tinymce/EditorImage.vue';
import plugins from '@/demo/components/Tinymce/plugins';
import toolbar from '@/demo/components/Tinymce/toolbar';

export default {
  name: 'TinymceDemo',
  components: { Tinymce },
  data() {
    return {
      content: '',
      options: {
        height: 600,
        language: { en: 'en_US', pt: 'pt_BR', es: 'es_MX' }[this.$i18n.locale], // Converte pt que vem do this.$i18n.locale em pt_BR
        // language_url: `https://cdn.jsdelivr.net/npm/tinymce-lang/langs/${{ en: 'en_US', pt: 'pt_BR', es: 'es_MX' }[this.$i18n.locale]}.js`,
        body_class: 'panel-body',
        object_resizing: false,
        menubar: 'file edit insert view format table',
        toolbar,
        plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true,
      },
    };
  },
};
</script>
